import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {EngageWidget10} from '../../../_metronic/partials/widgets'
import {TablesWidgetURLScrubbingLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetURLScrubbingLogs'
import {axiosClientAuth} from '../../utils'
import StatisticsWidgetClient from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetClient'

const DashboardPage: FC = () => {
  const [urlData, setUrlData] = useState([])

  const [pointerToFetchAgain, setPointerToFetchAgain] = useState(false)
  const [dashbaordData, setDashboardData] = useState({})

  useEffect(() => {
    axiosClientAuth
      .get('/admin/dashboard')
      .then((resp) => setDashboardData(resp.data.data))
      .catch((err) => console.log(err))

    axiosClientAuth
      .get('/admin/urls')
      .then((res) => {
        if (res.data.status) {
          setUrlData(res.data.data.urls)
        }
      })
      .catch((err) => console.log(err))
  }, [pointerToFetchAgain])

  const {setLayoutType} = useLayout()

  useEffect(() => {
    setLayoutType('dark-sidebar')
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <StatisticsWidgetClient data={dashbaordData} className='card-xl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
          <EngageWidget10 data={dashbaordData} className='card-xl-stretch bg-primary' />
        </div>
      </div>

      <div className='row g-5 g-xl-10'>
        <div className='col-lg-12'>
          <TablesWidgetURLScrubbingLogs
            className='card-xxl-stretch mb-5 mb-xl-10'
            urlData={urlData}
            pointerToFetchAgain={pointerToFetchAgain}
            setPointerToFetchAgain={setPointerToFetchAgain}
          />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('dashboard')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
