import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {TablesWidgetIPLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetIPLogs'
import {axiosClientAuth} from '../../utils'

const IPList: FC = () => {
  const [ipData, setIpData] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('admin/node_ip_list')
      .then((res) => setIpData(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          <TablesWidgetIPLogs className='card-xxl-stretch mb-5 mb-xl-10' ipData={ipData} />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const IPListWrapper: FC = () => {
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('iplist')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Vendor List</PageTitle>
      <IPList />
    </>
  )
}

export {IPListWrapper}
