import {FC, useEffect} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import TableWidgeMytIp from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetMyIp'

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MyIp = () => {
  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>My IP</PageTitle>

      <div className='row'>
        <div className='col-12'>
          <TableWidgeMytIp />
        </div>
      </div>
    </>
  )
}

const MyIpWrapper: FC = () => {
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('myip')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Vendor List</PageTitle>
      <MyIp />
    </>
  )
}

export default MyIpWrapper
