import React from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem to='/dashboard' icon='element-11' title='Dashboard' />
      <SidebarMenuItem to='/urlapproval' icon='verify' title='URL Approval' />
      <SidebarMenuItem to='/managetemplate' icon='setting-3' title='URL Mapping' />
      <SidebarMenuItem to='/smsreport' icon='notepad' title='Message Data' />
      <SidebarMenuItem to='/myip' icon='files-tablet' title='My IP' />
      <SidebarMenuItem to='/iplist' icon='text-number' title='Vendor List' />
      <SidebarMenuItem to='/operatordata' icon='book' title='Account Statistics' />
      <SidebarMenuItemWithSub to='/account' icon='information-5' title='Account Control'>
        <SidebarMenuItem to='/rolesmanagement' title='Roles Management' hasBullet={true} />
        <SidebarMenuItem to='/usermanagement' title='User Management' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
