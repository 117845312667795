/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table'
import moment from 'moment'
import clsx from 'clsx'
import {axiosClientAuth} from '../../../../../app/utils'
import Swal from 'sweetalert2'
import {DatePicker} from 'antd'
import {useLayout} from '../../../../layout/core'

type Props = {
  className: string
  ipData: any
}

type columnType = {
  pe_id: string
  address: string
  created_at: string
  status: string
  uuid: string
  blacklisted_by_operator: boolean
}

const TablesWidgetIPLogs: React.FC<Props> = ({className, ipData}) => {
  const {setUuidOfVendorForIpList} = useLayout()
  const [dateFilter, setDateFilter] = useState([])
  const [mData, setMData] = useState(ipData)

  useEffect(() => {
    setMData(ipData)
  }, [ipData])

  const columns: ReadonlyArray<Column<any>> = useMemo(
    () => [
      {
        id: 'DATE',
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({...props}) => {
          return (
            <>
              <span>{moment(props.value).format('DD/MM/YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'Vendor Name',
        accessor: 'name',
        Cell: ({...props}) => {
          return (
            <>
              <span>{props.value}</span>
            </>
          )
        },
      },
      {
        Header: 'Vendor Type',
        accessor: 'node.node_name',
        Cell: ({...props}) => {
          return (
            <>
              <span>{props.value}</span>
            </>
          )
        },
      },
      {
        Header: 'TOTAL IP',
        accessor: 'ip_count.total',
      },
      {
        Header: 'Whitelisted',
        accessor: 'ip_count.whitelisted',
      },
      {
        Header: 'Blacklisted',
        accessor: 'ip_count.blacklisted',
      },
      {
        Header: 'ACTION',
        accessor: 'uuid',
        Cell: ({...props}) => {
          return (
            <>
              <button
                className='btn h-35px btn-icon btn btn-secondary px-10'
                onClick={() => setUuidOfVendorForIpList(props.value)}
                id='kt_drawer_ip_list_toggle'
              >
                View
              </button>
            </>
          )
        },
      },
    ],
    []
  )

  const ipTable = useTable(
    {
      columns,
      data: mData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {RangePicker} = DatePicker

  const onDateChange = (_, val) => {
    setDateFilter(val)
    if (val.length > 0 && val[0] != '') {
      setMData(
        ipData.filter((item) => {
          const routeDate = moment(new Date(item?.created_at)).format('DD-MM-YYYY')
          const startDate = moment(new Date(val[0])).format('DD-MM-YYYY')
          const endDate = moment(new Date(val[1])).format('DD-MM-YYYY')

          return routeDate >= startDate && routeDate <= endDate
        })
      )
    } else {
      setMData(ipData)
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vendor Whitelist Logs</span>
          <span className='text-muted fw-semibold fs-7'>Total {ipData?.length} Vendor</span>
        </h3>
        <div className='d-flex align-items-center'>
          <RangePicker
            className='bg-gray-100 border-0 text-white me-2'
            style={{height: '35px'}}
            onChange={onDateChange}
          />
          {/* <select
            className='form-select form-select-sm form-select-solid w-auto'
            value={ipTable?.headers[3].filterValue}
            onChange={(e) => ipTable?.headers[3].setFilter(e.target.value)}
          >
            {['All', 'whitelist', 'blacklist'].map((item) => (
              <option value={item == 'All' ? '' : item}>{item}</option>
            ))}
          </select> */}
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Vendor'
              value={ipTable?.state.globalFilter}
              onChange={(e) => ipTable?.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...ipTable?.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {ipTable?.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...ipTable?.getTableBodyProps()}>
              {(ipTable?.page.length > 0 &&
                ipTable?.page.map((row) => {
                  ipTable?.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                <tr>
                  <td colSpan={5} className='text-center'>
                    <span className='fw-bold'>No data available in table</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={ipTable?.state.pageSize}
                onChange={(e) => ipTable?.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {ipTable?.state.pageIndex + 1} of {ipTable?.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => ipTable?.previousPage()}
                disabled={!ipTable?.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => ipTable?.nextPage()}
                disabled={!ipTable?.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TablesWidgetIPLogs}
