import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'

type Props = {
  urlDrawerData: any
}

const DrawerURLRedirection: React.FC<Props> = ({urlDrawerData}) => {
  const [isSnapshotView, setIsSnapshotView] = useState(false)

  return (
    <div
      id='url-redirection'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='url-redirection'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#url-redirection-toggle'
      data-kt-drawer-close='#url-redirection-close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='url-redirection-header'>
          <h3
            className='card-title fw-bolder text-dark cursor-pointer'
            onClick={() => setIsSnapshotView(false)}
          >
            URL Redirections
          </h3>
          <h3
            className='card-title fw-bolder text-dark cursor-pointer'
            onClick={() => setIsSnapshotView(true)}
          >
            Snapshot View
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='url-redirection-close'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>

        <div className='card-body position-relative' id='url-redirection-body'>
          <div
            id='url-redirection-scroll'
            className='position-relative scroll-y me-n5 pe-5'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#url-redirection-body'
            data-kt-scroll-dependencies='#url-redirection-header, #url-redirection-footer'
            data-kt-scroll-offset='5px'
          >
            <div className='timeline'>
              {isSnapshotView ? (
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                    <div className='symbol-label bg-light'>
                      <KTIcon iconName='screen' className='fs-2 text-gray-500' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-2'>
                    <div className='pe-3 mb-5'>
                      <div className='fs-5 fw-bold mb-2'>Preview</div>
                    </div>

                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7'>
                        <div className='overlay me-10'>
                          <div className='overlay-wrapper'>
                            <img
                              alt='img'
                              className='rounded w-750px'
                              src={urlDrawerData?.screenshot}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='timeline'>
                  {urlDrawerData?.redirects?.map((url: string, index: number) => (
                    <div className='timeline-item'>
                      <div className='timeline-line w-40px'></div>

                      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                        <div className='symbol-label bg-light'>
                          <KTIcon iconName='fasten' className='fs-2 text-gray-500' />
                        </div>
                      </div>

                      <div className='timeline-content mb-10 mt-2'>
                        <div className='pe-3 mb-5'>
                          <div className='fs-5 fw-bold mb-2'>{url}</div>
                        </div>

                        {index == urlDrawerData?.redirects.length - 1 ? (
                          <div className='overflow-auto pb-5'>
                            {urlDrawerData?.request_urls?.map((url: string) => (
                              <div className='d-flex align-items-center justify-content-between border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
                                <a
                                  href='#'
                                  className='fs-5 text-dark text-hover-primary fw-bold min-w-200px'
                                >
                                  {url}
                                </a>

                                <a
                                  href={url}
                                  target='__blank'
                                  className='btn btn-sm btn-light btn-active-light-primary '
                                >
                                  View
                                </a>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DrawerURLRedirection}
