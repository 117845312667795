import {useEffect, useState} from 'react'
import {axiosClientAuth} from '../../../utils'
import {Card1} from '../../../../_metronic/partials/content/cards/Card1'
import {useLayout} from '../../../../_metronic/layout/core'

const Tickets = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('/support/admin-support-ticket')
      .then((resp) => setData(resp.data?.data))
      .catch(() => {})
  }, [])

  const {setCurrentTicket, setMyTkt} = useLayout()

  const sendMessage = (uuid) => {
    axiosClientAuth
      .get('/support/operator-ticket?ticket_uuid=' + uuid)
      .then((res) => setCurrentTicket(res.data.data))
      .catch((err) => console.log(err))
  }

  return (
    <div>
      <div className='row g-6 g-xl-9'>
        {data?.map((item: any, index: number) => (
          <div className='col-md-4 col-xxl-4'>
            <Card1
              name={item.title}
              job={item.description}
              status={item.status}
              item={item}
              sendMessage={() => {
                sendMessage(item.uuid)
                setMyTkt(false)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tickets
