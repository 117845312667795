import {DatePicker} from 'antd'

export default function OperatorSummaryToolbar() {
  const {RangePicker} = DatePicker
  return (
    <>
      <RangePicker className='bg-gray-100 text-white border-0' />
    </>
  )
}
