import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {CardsWidgetPlanDetails} from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetPlanDetails'
import {ListsWidgetURLHighlight} from '../../../_metronic/partials/widgets/_new/lists/ListsWidgetURLHighlight'
import {CardsWidgetSMSDetails} from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetSMSDetails'
import {ListsWidgetIPHighlight} from '../../../_metronic/partials/widgets/_new/lists/ListWidgetIPHighlight'
import {TablesWidgetScrubbingLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetScrubbingLogs'
import {axiosClientAuth} from '../../utils'
import {useSearchParams} from 'react-router-dom'
import CardsDashboardMain from '../../../_metronic/partials/widgets/_new/cards/CardsDashboardMain'

const OperatorSummary = () => {
  const [searchParams] = useSearchParams()

  const [userData, setUserData] = useState<any>()
  const [scrubbingLogs, setScrubbingLogs] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get(`main/get-scrubbing-logs?uuid=${searchParams.get('user')}`)
      .then((res) => setScrubbingLogs(res.data.data))
      .catch((err) => console.log(err))

    axiosClientAuth
      .get(`auth/pe-user?uuid=${searchParams.get('user')}`)
      .then((res) => setUserData(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsDashboardMain data={userData} className='h-100' />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidgetSMSDetails
            className='h-100 mb-5 mb-xl-10'
            success={userData?.message_count?.success}
            failed={userData?.message_count?.failed}
            total={userData?.message_count?.total}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetURLHighlight
            className='h-100 mb-5 mb-xl-10'
            pendingUrl={userData?.url_count?.pending}
            approvedUrl={userData?.url_count?.whitelisted_by_operator}
            rejectedUrl={userData?.url_count?.blacklisted_by_operator}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetIPHighlight
            className='h-100 mb-5 mb-xl-10'
            route={userData?.route_count}
            template={userData?.template_maping_count}
          />
        </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <TablesWidgetScrubbingLogs
            className='card-xxl-stretch mb-5'
            scrubbingLogs={scrubbingLogs}
          />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Account Statistics',
    path: '/operatordata',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OperatorSummaryWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('operatorsummary')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'Account Details'})}
      </PageTitle>
      <OperatorSummary />
    </>
  )
}

export default OperatorSummaryWrapper
