import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {ChatInner} from '../../../../_metronic/partials'
import {axiosClientAuth} from '../../../utils'
import {Card1} from '../../../../_metronic/partials/content/cards/Card1'
import moment from 'moment'
import {useLayout} from '../../../../_metronic/layout/core'

const MyTickets = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('/support/tcil-ticket')
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  const {setCurrentTicket, setMyTkt} = useLayout()

  const sendMessage = (uuid) => {
    axiosClientAuth
      .get('/support/tcil-ticket?ticket_uuid=' + uuid)
      .then((res) => setCurrentTicket(res.data.data))
      .catch((err) => console.log(err))
  }

  return (
    <div>
      <div className='row g-6 g-xl-9'>
        {data?.map((item: any, index: number) => (
          <div className='col-md-4 col-xxl-4'>
            <Card1
              name={item.title}
              job={item.description}
              status={item.status}
              item={item}
              sendMessage={() => {
                sendMessage(item.uuid)
                setMyTkt(true)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MyTickets
