/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table'
import moment from 'moment'
import Swal from 'sweetalert2'
import {axiosClientAuth} from '../../../../../app/utils'
import clsx from 'clsx'
import {DrawerURLRedirection} from '../drawers/DrawerURLRedirection'
import {DatePicker} from 'antd'
import {useAuth} from '../../../../../app/modules/auth'

interface urlDataType {
  name: string
  url: string
  created_at: string
  status: boolean
  uuid: string
  updated_at: string
  pe_id: string
}

type Props = {
  className: string
  urlData: urlDataType[]
  pointerToFetchAgain: boolean
  setPointerToFetchAgain: any
}

type columnType = {
  [x: string]: any
  name: string
  url: string
  created_at: string
  status: boolean
  updated_at: string
  pe_id: string
}

const TablesWidgetURLScrubbingLogs: React.FC<Props> = ({
  className,
  urlData,
  pointerToFetchAgain,
  setPointerToFetchAgain,
}) => {
  const {checkPermission} = useAuth()
  const [filteredData, setFilteredData] = useState(urlData)

  useEffect(() => {
    if (urlData?.length > 0) {
      setFilteredData(urlData)
    }
  }, [urlData])

  const updateUrl = (uuid: string, status: boolean) =>
    axiosClientAuth
      .patch('/admin/blacklist_url', {
        uuid,
        status,
      })
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            titleText: 'Success!',
            text: 'URL Updated Successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((isConfirm) => {
            isConfirm && setPointerToFetchAgain(!pointerToFetchAgain)
          })
        }
      })
      .catch((error) => console.log(error))

  const [urlDrawerData, setUrlDrawerData] = useState()

  const fetchUrlRedirection = (uuid: string) =>
    axiosClientAuth
      .get(`admin/requests-redirects?url_uuid=${uuid}`)
      .then((res) => setUrlDrawerData(res.data.data))
      .catch((err) => console.log(err))

  const columns: ReadonlyArray<Column<columnType>> = useMemo(
    () => [
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({...props}) => (
          <div className='position-relative ps-6 pe-3 py-2'>
            <div
              className={`position-absolute start-0 top-0 w-4px h-100 rounded-2 ${
                props.row.original.is_blacklisted_by_operator == false
                  ? 'bg-success'
                  : props.row.original.is_blacklisted_by_operator == true
                  ? 'bg-danger'
                  : 'bg-warning'
              }`}
            />
            <a className='mb-1 text-dark text-hover-primary fw-bold'>{props.row.original.url}</a>
            <div className='fs-7 text-muted fw-bold'>
              Updated on {moment(props.row.original.updated_at).format('DD MMM YYYY')}
            </div>
          </div>
        ),
      },
      {
        Header: 'PE DETAILS',
        accessor: 'pe_name',
        Cell: ({...props}) => (
          <>
            <span>{props.value}</span>
            <span className='d-block text-muted fw-semibold'>{props.row.original.pe_id}</span>
          </>
        ),
      },
      {
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({...props}) => {
          return (
            <>
              <span>{moment(props.value).format('DD/MM/YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'TRUSTED URL',
        accessor: 'is_trusted',
        Cell: ({...props}) =>
          props.value === true ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Yes</span>
          ) : (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>No</span>
          ),
      },
      {
        Header: 'TCIL STATUS',
        accessor: 'is_blacklisted_by_tcil',
        Cell: ({...props}) => {
          return props.value === false ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Whitelist</span>
          ) : props.value === true ? (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>Blacklist</span>
          ) : (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>Pending</span>
          )
        },
      },
      {
        Header: 'STATUS',
        accessor: 'is_blacklisted_by_operator',
        Cell: ({...props}) => {
          return props.value === false ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Whitelist</span>
          ) : props.value === true ? (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>Blacklist</span>
          ) : (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>Pending</span>
          )
        },
      },
      {
        Header: 'Actions',
        Cell: ({...props}) => {
          const uuid = props.row.original.uuid
          const status = props.row.original.is_blacklisted_by_operator
          return (
            <div className='w-200px'>
              <button
                id='url-redirection-toggle'
                className='btn h-35px w-65px btn-icon btn-light-info me-3'
                onClick={() => fetchUrlRedirection(uuid)}
              >
                View
              </button>
              <button
                className={clsx(
                  'btn h-35px btn-icon btn btn-light-primary me-3',
                  status == false && 'disabled btn-secondary'
                )}
                disabled={status == false}
                onClick={() => updateUrl(uuid, false)}
              >
                <KTIcon
                  iconName='check'
                  className={clsx(
                    'text-hover-white fs-1',
                    status == false ? 'text-gray' : 'text-primary'
                  )}
                />
              </button>
              <button
                className={clsx(
                  'btn h-35px btn-icon btn btn-light-danger',
                  status == true && 'disabled btn-secondary'
                )}
                disabled={status == true}
                onClick={() => updateUrl(uuid, true)}
              >
                <KTIcon
                  iconName='cross'
                  className={clsx(
                    'text-hover-white fs-1',
                    status == true ? 'text-gray' : 'text-danger'
                  )}
                />
              </button>
            </div>
          )
        },
      },
    ],
    [urlData]
  )

  const scrubbingLogsTable = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) return column.accessor || column.id
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {RangePicker} = DatePicker

  const onDateChange = (value, datestring: any) => {
    if (datestring.length == 0 || (datestring[0] == '' && datestring[1] == '')) {
      setFilteredData(urlData)
      return
    } else {
      const temp = urlData.filter((route) => {
        const routeDate = moment(new Date(route?.created_at)).format('YYYY-MM-DD')
        const startDate = moment(new Date(datestring[0])).format('YYYY-MM-DD')
        const endDate = moment(new Date(datestring[1])).format('YYYY-MM-DD')

        return routeDate >= startDate && routeDate <= endDate
      })
      setFilteredData(temp)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>URL Approvals</span>
          <span className='text-muted fw-semibold fs-7'>Total {urlData?.length} URL</span>
        </h3>
        <div className='d-flex align-items-center'>
          <RangePicker
            className='bg-gray-100 border-0 text-white me-2'
            onChange={onDateChange}
            style={{height: '34px'}}
          />
          <span className='mx-2'>
            <input
              type='checkbox'
              id='trustedCheckBox'
              onChange={() =>
                scrubbingLogsTable?.headers[3].setFilter((prev) => (prev ? !prev : true))
              }
              checked={scrubbingLogsTable?.headers[3].filterValue || false}
            />
            <label className='ms-2' htmlFor='trustedCheckBox'>
              Is Trusted
            </label>
          </span>

          <select
            className='form-select form-select-sm form-select-solid w-auto'
            value={scrubbingLogsTable?.headers[4].filterValue}
            onChange={(e) => scrubbingLogsTable?.headers[4].setFilter(e.target.value)}
          >
            {['TCIL Status', 'false', 'null', 'true'].map((item) => (
              <option value={item == 'TCIL Status' ? '' : item}>
                {item == 'TCIL Status'
                  ? 'TCIL Status'
                  : item == 'false'
                  ? 'Whitelisted'
                  : item == 'null'
                  ? 'Pending'
                  : 'Blacklisted'}
              </option>
            ))}
          </select>

          <select
            className='form-select form-select-sm form-select-solid w-auto ms-2'
            value={scrubbingLogsTable?.headers[5].filterValue}
            onChange={(e) => scrubbingLogsTable?.headers[5].setFilter(e.target.value)}
          >
            {['Status', 'false', 'null', 'true'].map((item) => (
              <option value={item == 'Status' ? '' : item}>
                {item == 'Status'
                  ? 'Status'
                  : item == 'false'
                  ? 'Whitelisted'
                  : item == 'null'
                  ? 'Pending'
                  : 'Blacklisted'}
              </option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search URL'
              value={scrubbingLogsTable?.state.globalFilter}
              onChange={(e) => scrubbingLogsTable?.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...scrubbingLogsTable?.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {scrubbingLogsTable?.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...scrubbingLogsTable?.getTableBodyProps()}>
              {(scrubbingLogsTable?.page.length > 0 &&
                scrubbingLogsTable?.page.map((row) => {
                  scrubbingLogsTable?.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                <tr>
                  <td colSpan={5} className='text-center'>
                    <span className='fw-bold'>No data available in table</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={scrubbingLogsTable?.state.pageSize}
                onChange={(e) => scrubbingLogsTable?.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {scrubbingLogsTable?.state.pageIndex + 1} of{' '}
                  {scrubbingLogsTable?.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => scrubbingLogsTable?.previousPage()}
                disabled={!scrubbingLogsTable?.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => scrubbingLogsTable?.nextPage()}
                disabled={!scrubbingLogsTable?.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>

      <DrawerURLRedirection urlDrawerData={urlDrawerData} />
    </div>
  )
}

export {TablesWidgetURLScrubbingLogs}
