import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { axiosClientAuth } from '../../utils'
import { KTIcon } from '../../../_metronic/helpers'

const MyIpRightToolbar = () => {
  const [input, setInput] = useState({
    name: '',
    address: '',
  })

  const onChangeHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if(!input.name || !input.address){
      Swal.fire({
        titleText: 'Error!',
        text: 'Name and address is required !!!',
        icon: 'error',
        confirmButtonText: 'Ok',
      })
      return;
    }
    axiosClientAuth
      .post('/admin/ip-addresses', input)
      .then((res) =>
        Swal.fire({
          titleText: 'Success!',
          text: 'IP Added Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((isConfirm) => {
          isConfirm && window.location.reload()
        })
      )
      .catch((error) => {
        if (error?.response?.data?.data?.address) {
          Swal.fire({
            titleText: 'Error!',
            text: 'Duplicate IP Found !!!',
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        } else {
          Swal.fire({
            titleText: 'Error!',
            text: 'Data is not valid please enter correct data',
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }
      })
  }

  return (
    <>
      <button
        className='btn btn-sm fw-bold btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_add_user'
      >
        Add IP
      </button>

      {/* My Modal */}
      <div className='modal fade' id='kt_modal_add_user' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_add_user_header'>
              <h2 className='fw-bold'>Add IP</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-bs-dismiss='modal'>
                <KTIcon className='fs-1' iconName='cross' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form className='form' onSubmit={onSubmit}>
                <div className='d-flex flex-column scroll-y me-n7 pe-7'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-semibold fs-6 mb-2'>Name</label>
                    <input
                      type='text'
                      name='name'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='Name'
                      value={input.name}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-semibold fs-6 mb-2'>IP Address</label>
                    <input
                      type='text'
                      name='address'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter IP Address'
                      value={input.address}
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Submit</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyIpRightToolbar
