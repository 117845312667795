import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  className: string
  data: any
}

const colors = [
  'danger',
  'primary',
  'warning',
  'info',
]

const StatisticsWidgetClient = ({ className, data }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={`card card-flush ${className}`}>
      <div className="h-100 card-body p-9">
        <div className="fs-2hx fw-bold">{data?.pe_count}</div>
        <div className="fs-4 fw-semibold text-gray-400 mb-7">Total PE registered</div>
        <div className="symbol-group symbol-hover mb-9">
          {
            data?.first_letters?.map((leter, i) => (
              <div
                className="symbol symbol-35px symbol-circle"
              // data-bs-toggle="tooltip"
              // title="Alan Warden"
              >
                <span className={`symbol-label bg-${colors[i % 4]} text-inverse-${colors[i % 4]} fw-bold`}>
                  {leter}
                </span>
              </div>
            ))
          }
          {/* <a
            href="javascript:void(0)"
            className="symbol symbol-35px symbol-circle"
            onClick={() => navigate('/operatordata')}
          >
            <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
              +42
            </span>
          </a> */}
        </div>
        <div className="d-flex">
          <a
            href="javascript:void(0)"
            className="btn btn-primary btn-sm me-3"
            onClick={() => navigate('/operatordata')}
          >
            All Clients
          </a>
          <a
            href="javascript:void(0)"
            className="btn btn-light btn-sm"
          >
            Invite New
          </a>
        </div>
      </div>
    </div>
  )
}

export default StatisticsWidgetClient