import {toAbsoluteUrl} from '../../../../helpers'

export default function CardsDashboardMain({className, data}) {
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: '#F1416C',
        backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
      }}
    >
      <div className='card-header pt-5'>
        <h3 className='card-title text-white fw-bold'>My IP's</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-4'>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Total IP's</div>
            <div className='fw-bolder text-white text-xxl-end'>{data?.ips_count?.total}</div>
          </div>

          <div className='separator separator-dashed my-3'></div>

          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Whitelisted IP's</div>
            <div className='fw-bolder text-white text-xxl-end'>
              {data?.ips_count?.whitelisted_by_operator}
            </div>
          </div>

          <div className='separator separator-dashed my-3'></div>

          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Blacklisted IP's</div>
            <div className='fw-bolder text-white text-xxl-end'>
              {data?.ips_count?.blacklisted_by_operator}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
