import {DatePicker} from 'antd'
import {useLayout} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import {useEffect} from 'react'
import {axiosClientAuth} from '../../utils'

export default function SMSReportToolbar() {
  const {
    setDateFilterSmsReport,
    scrubbingLogsPeidFilter,
    setScrubbingLogsPeidFilter,
    listOfPeidForScrubbingLogs,
    setListOfPeidForScrubbingLogs,
  } = useLayout()

  const {RangePicker} = DatePicker

  useEffect(() => {
    axiosClientAuth.get('/auth/pe-user').then((res) => {
      const set = new Set()
      res?.data?.data?.forEach((t: any) => {
        set.add(t?.pe_id)
      })
      setListOfPeidForScrubbingLogs(set)
    })
  }, [])

  return (
    <div className='d-flex align-items-center'>
      <RangePicker
        className='bg-gray-100 border-0 text-white'
        style={{height: '35px'}}
        onChange={(_, val) => setDateFilterSmsReport(val)}
      />

      <select
        value={scrubbingLogsPeidFilter}
        className='bg-secondary w-auto ms-3 px-1 border-0'
        style={{height: '40px', borderRadius: '6px'}}
        onChange={(e: any) => setScrubbingLogsPeidFilter(e.target.value)}
      >
        {['PE ID', ...listOfPeidForScrubbingLogs].map((item) => (
          <option value={item == 'PE ID' ? '' : item}>{item}</option>
        ))}
      </select>

      {scrubbingLogsPeidFilter ? (
        <button
          className='btn h-40px btn-danger ms-3 d-flex align-items-center'
          onClick={() => setScrubbingLogsPeidFilter('')}
        >
          Clear
          <KTIcon iconName='cross' className='text-white fs-1 ms-5' />
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}
