/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import ModalEditRole from '../../../_metronic/partials/widgets/_new/modal/ModalEditRole'
import {useNavigate} from 'react-router-dom'
import {Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table'
import {DatePicker} from 'antd'
import {axiosClientAuth} from '../../utils'
import Swal from 'sweetalert2'
import moment from 'moment'
import ModalEditUser from '../../../_metronic/partials/widgets/_new/modal/ModalEditUser'

export const UserManagement: FC<any> = () => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState([])
  const [currData, setCurrData] = useState()

  const getData = useCallback(() => {
    axiosClientAuth
      .get('/auth/get-users?is_tm_staff=True')
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const deleteUser = (user) => {
    Swal.fire({
      titleText: "You won't be able to revert this?",
      text: 'Are you sure?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
    }).then((result) => {
      console.log(result)
      if (result?.isConfirmed) {
        axiosClientAuth
          .delete('/auth/add-user?uuid=' + user.uuid)
          .then((res) => {
            Swal.fire({
              titleText: 'Success!',
              text: 'User Deleted Successfully',
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then((isConfirm) => {
              isConfirm && window.location.reload()
            })
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
      }
    })
  }

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          <div className='card card-flush'>
            <div className='card-body pt-0'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                id='kt_permissions_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Name</th>
                    <th className='min-w-125px'>Email</th>
                    <th className='min-w-250px'>Roles</th>
                    <th className='min-w-125px'>Created Date</th>
                    <th className='text-end min-w-100px'>Actions</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {data?.map((ele: any) => (
                    <tr key={ele.uuid}>
                      <td>{ele.name}</td>
                      <td>{ele.email}</td>
                      <td>
                        <span className='badge badge-light-primary fs-7 m-1'>
                          {ele.group_obj?.name}
                        </span>
                      </td>
                      <td>{moment(ele.created_at).format('DD MMM, YYYY hh:mm A')}</td>
                      <td className='text-end'>
                        {/* <button
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            onClick={() => setShow(true)}
                          >
                            <KTIcon iconName='notepad-edit' className='fs-4' />
                          </button> */}
                        <button
                          className='btn btn-icon btn-active-light-primary w-30px h-30px'
                          onClick={() => {
                            setShow(true)
                            setCurrData(ele)
                          }}
                        >
                          <KTIcon iconName='notepad-edit' className='fs-4' />
                        </button>
                        <button
                          className='btn btn-icon btn-active-light-primary w-30px h-30px'
                          onClick={() => deleteUser(ele)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.5'
                                d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.5'
                                d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='modal fade' id='kt_modal_add_permission' tabIndex={-1} aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bold'>Add a Permission</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-permissions-modal-action='close'
                  >
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x={6}
                          y='17.3137'
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(-45 6 17.3137)'
                          fill='currentColor'
                        />
                        <rect
                          x='7.41422'
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(45 7.41422 6)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form id='kt_modal_add_permission_form' className='form' action='#'>
                    <div className='fv-row mb-7'>
                      <label className='fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Add Roles</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='popover'
                          data-bs-trigger='hover'
                          data-bs-html='true'
                          data-bs-content='Permission names is required to be unique.'
                        />
                      </label>
                      <input
                        className='form-control form-control-solid'
                        placeholder='Enter a permission name'
                        name='permission_name'
                      />
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='form-check form-check-custom form-check-solid me-9'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          defaultValue=''
                          name='permissions_core'
                          id='kt_permissions_core'
                        />
                        <span
                          className='form-check-label'
                          // htmlFor="kt_permissions_core"
                        >
                          Set as core permission
                        </span>
                      </label>
                    </div>
                    <div className='text-gray-600'>
                      Permission set as a<strong className='me-1'>Core Permission</strong>will be
                      locked and
                      <strong className='me-1'>not editable</strong>in future
                    </div>
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        className='btn btn-light me-3'
                        data-kt-permissions-modal-action='cancel'
                      >
                        Discard
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-permissions-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                        <span className='indicator-progress'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalEditUser show={show} setShow={setShow} currData={currData} />
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UserManagementWrapper: FC<any> = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('usermanagement')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'User Management'})}
      </PageTitle>
      <UserManagement />
    </>
  )
}

export {UserManagementWrapper}
