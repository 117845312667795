/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import ModalAddRole from '../../../_metronic/partials/widgets/_new/modal/ModalAddRole'
import {axiosClientAuth} from '../../utils'
import Swal from 'sweetalert2'
import ModalEditRole from '../../../_metronic/partials/widgets/_new/modal/ModalEditRole'
import ModalViewRole from '../../../_metronic/partials/widgets/_new/modal/ModalViewRole'

const RolesManagement: FC = () => {
  const [show, setShow] = useState(false)
  const [editRoleShow, setEditRoleShow] = useState(false)
  const [viewRoleShow, setViewRoleShow] = useState(false)
  const [currData, setCurrData] = useState()
  const [data, setData] = useState([])

  const getData = async () => {
    try {
      const {data: mData} = await axiosClientAuth.get('/auth/api-permission-group')
      setData(mData.data)
      console.log(mData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const deleteRole = (uuid: string) => {
    Swal.fire({
      titleText: 'Are you sure?',
      text: "This action can't be reverted",
      icon: 'warning',
      confirmButtonText: 'Yes, delete',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((action) => {
      if (action.isConfirmed) {
        axiosClientAuth
          .delete(`/auth/api-permission-group?uuid=${uuid}`)
          .then((res) => {
            console.log(res)
            Swal.fire({
              titleText: 'Success!',
              text: 'Role deleted Successfully',
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then((isConfirm) => {
              isConfirm && window.location.reload()
            })
          })
          .catch((err) => console.log(err))
      }
    })
  }

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {data?.map((ele: any) => (
          <div key={ele.uuid} className='col-md-4'>
            <div className='card card-flush h-md-100'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{ele.name}</h2>
                </div>
              </div>
              <div className='card-body pt-1'>
                <div className='fw-bold text-gray-600 mb-5'>
                  Total users with this role: {ele.total_users}
                </div>
                <div className='d-flex flex-column text-gray-600'>
                  {ele?.available_permissions?.map((permission: any) => (
                    <div className='d-flex align-items-center py-2'>
                      <span className='bullet bg-primary me-3' />
                      {permission?.permission?.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className='card-footer flex-wrap pt-0'>
                <button
                  type='button'
                  className='btn btn-light btn-active-primary my-1 me-2'
                  onClick={() => {
                    setCurrData(ele)
                    setViewRoleShow(true)
                  }}
                >
                  View Role
                </button>
                <button
                  type='button'
                  className='btn btn-light btn-active-light-primary me-2 my-1'
                  onClick={() => {
                    setCurrData(ele)
                    setEditRoleShow(true)
                  }}
                >
                  Edit Role
                </button>
                <button
                  type='button'
                  className='btn btn-light btn-active-primary my-1 me-2'
                  onClick={() => deleteRole(ele?.uuid)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className='col-md-4'>
          <div className='card h-md-100'>
            <div className='card-body d-flex flex-center'>
              <button type='button' className='btn btn-clear d-flex flex-column flex-center'>
                <img
                  src='/media/illustrations/sketchy-1/4.png'
                  alt=''
                  className='mw-100 mh-150px mb-7'
                />
                <div
                  className='fw-bold fs-3 text-gray-600 text-hover-primary'
                  onClick={() => setShow(true)}
                >
                  Add New Role
                </div>
              </button>
            </div>
          </div>
        </div>
        <ModalAddRole show={show} setShow={setShow} />
        <ModalEditRole show={editRoleShow} setShow={setEditRoleShow} curr={currData} />
        <ModalViewRole show={viewRoleShow} setShow={setViewRoleShow} curr={currData} />
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RolesManagementWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('rolesmanagement')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'Roles Management'})}
      </PageTitle>
      <RolesManagement />
    </>
  )
}

export {RolesManagementWrapper}
