import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {axiosClientAuth} from '../../utils'

const UserManagementRightToolbar = () => {
  const [roles, setRoles] = useState([])

  const getData = async () => {
    try {
      const {data: mData} = await axiosClientAuth.get('/auth/api-permission-group')
      setRoles(mData.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const [input, setInput] = useState({
    name: '',
    email: '',
    groups: '',
  })

  const onChangeHandler = (e) => {
    console.log(e)
    setInput((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    axiosClientAuth
      .post('/auth/add-user', {
        name: input.name,
        email: input.email,
        groups: input.groups,
      })
      .then((res) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'User created Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((isConfirm) => {
          isConfirm && window.location.reload()
        })
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <button
        className='btn btn-sm fw-bold btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_add_user'
      >
        Add User
      </button>

      {/* My Modal */}
      <div className='modal fade' id='kt_modal_add_user' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_add_user_header'>
              <h2 className='fw-bold'>Add User</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-bs-dismiss='modal'>
                <span className='svg-icon svg-icon-1'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x={6}
                      y='17.3137'
                      width={16}
                      height={2}
                      rx={1}
                      transform='rotate(-45 6 17.3137)'
                      fill='currentColor'
                    />
                    <rect
                      x='7.41422'
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform='rotate(45 7.41422 6)'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form className='form' onSubmit={onSubmit}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    <label className='required fw-semibold fs-6 mb-2'>Full Name</label>
                    <input
                      type='text'
                      name='name'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='Full name'
                      value={input.name}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-semibold fs-6 mb-2'>Email</label>
                    <input
                      type='email'
                      name='email'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='example@domain.com'
                      value={input.email}
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className='mb-7'>
                    <label className='required fw-semibold fs-6 mb-5'>Role</label>
                    {roles.map((role: any, i) => (
                      <React.Fragment key={role.uuid}>
                        <div className='d-flex fv-row'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input me-3'
                              name='groups'
                              type='radio'
                              value={role.uuid}
                              checked={input.groups == role.uuid}
                              onChange={onChangeHandler}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='kt_modal_update_role_option_0'
                            >
                              <div className='fw-bold text-gray-800'>{role.name}</div>
                              <div className='text-gray-600'>{role.description}</div>
                            </label>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-5' />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Submit</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserManagementRightToolbar
