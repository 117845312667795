import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {axiosClientAuth} from '../../utils'
import {TablesWidgetURLScrubbingLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetURLScrubbingLogs'

const URLApproval: FC = () => {
  const [urlData, setUrlData] = useState([])
  const [pointerToFetchAgain, setPointerToFetchAgain] = useState(false)

  useEffect(() => {
    axiosClientAuth
      .get('/admin/urls')
      .then((res) => {
        if (res.data.status) {
          setUrlData(res.data.data.urls)
        }
      })
      .catch((err) => console.log(err))
  }, [pointerToFetchAgain])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          <TablesWidgetURLScrubbingLogs
            className='card-xxl-stretch mb-5 mb-xl-10'
            urlData={urlData}
            pointerToFetchAgain={pointerToFetchAgain}
            setPointerToFetchAgain={setPointerToFetchAgain}
          />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const URLApprovalWrapper: FC = () => {
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('urlapproval')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>URL Approval</PageTitle>
      <URLApproval />
    </>
  )
}

export {URLApprovalWrapper}
