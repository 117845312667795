/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, loginVerify } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
})

const loginSchemaOTP = Yup.object().shape({
  peid: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('PE ID is required'),
})

const initialValues = {
  email: '',
}

const initialValuesOTP: any = {
  otp: {
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    f: '',
  },
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email)
        setOtpSent(true)
        setLoading(false)
        console.log(auth)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const formikOTP = useFormik({
    initialValues: initialValuesOTP,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const mOTP =
          values.otp.a + values.otp.b + values.otp.c + values.otp.d + values.otp.e + values.otp.f
        const resp = await loginVerify(formik.values.email, mOTP)
        saveAuth(resp.data.data)
        setCurrentUser(resp.data.data)
        window.location.reload()
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const onKeyUpFun = (e: any, prevData, nextData) => {
    if (e.keyCode === 8 || e.keyCode === 37) {
      const prev = document.getElementById('otpInput' + prevData) as HTMLInputElement
      if (prev) {
        prev.select()
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === 39
    ) {
      const next = document.getElementById('otpInput' + nextData) as HTMLInputElement
      if (next) {
        next.select()
      }
    }
  }

  const handlePaste = (ev: any) => {
    const clip = ev.clipboardData.getData('text')
    if (!isNaN(clip) && Number.isInteger(parseInt(clip)) && clip.length >= 6) {
      const tempOTP = {
        a: clip[0],
        b: clip[1],
        c: clip[2],
        d: clip[3],
        e: clip[4],
        f: clip[5],
      }
      formikOTP.setValues((prev) => ({ ...prev, otp: tempOTP }))
      const mInput = document.getElementById('otpInput6') as HTMLInputElement
      mInput.select()
    }
  }

  const theme = localStorage.getItem('kt_theme_mode_value')

  return otpSent ? (
    <form
      className='form w-100'
      onSubmit={formikOTP.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        {theme == 'light'
          ? <img src='/media/logos/goflipo_color.png' className='w-100' />
          : <img src='/media/logos/goflipo_white.png' className='w-100' />
        }
      </div>

      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Please enter the Email ID to login</div>
      </div>

      {formikOTP.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formikOTP.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          Type your 6 digit security code
        </label>
        <div className='d-flex gap-2 mt-2'>
          <input
            className={'form-control bg-transparent h-60px fs-2x text-center'}
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.a}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, a: e.target.value } }))
            }}
            id='otpInput1'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, '', 2)}
          />
          <input
            className='form-control bg-transparent h-60px fs-2x text-center'
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.b}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, b: e.target.value } }))
            }}
            id='otpInput2'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, 1, 3)}
          />
          <input
            className='form-control bg-transparent h-60px fs-2x text-center'
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.c}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, c: e.target.value } }))
            }}
            id='otpInput3'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, 2, 4)}
          />
          <input
            className='form-control bg-transparent h-60px fs-2x text-center'
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.d}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, d: e.target.value } }))
            }}
            id='otpInput4'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, 3, 5)}
          />
          <input
            className='form-control bg-transparent h-60px fs-2x text-center'
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.e}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, e: e.target.value } }))
            }}
            id='otpInput5'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, 4, 6)}
          />
          <input
            className='form-control bg-transparent h-60px fs-2x text-center'
            type='text'
            autoComplete='off'
            maxLength={1}
            value={formikOTP.values.otp.f}
            onChange={(e) => {
              formikOTP.setValues((prev) => ({ ...prev, otp: { ...prev.otp, f: e.target.value } }))
            }}
            id='otpInput6'
            onPaste={handlePaste}
            onKeyUp={(e) => onKeyUpFun(e, 5, '')}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formikOTP.isSubmitting || !formikOTP.isValid}
        >
          {!loading && <span className='indicator-label'>Submit OTP</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Need any help ?{' '}
        <Link to='#' className='link-primary'>
          Contact Us
        </Link>
      </div>
    </form>
  ) : (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        {theme == 'light'
          ? <img src='/media/logos/goflipo_color.png' className='w-100' />
          : <img src='/media/logos/goflipo_white.png' className='w-100' />
        }
      </div>

      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Please enter the Email ID to login</div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Enter your email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Get OTP</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Need any help ?{' '}
        <Link to='#' className='link-primary'>
          Contact Us
        </Link>
      </div>
    </form>
  )
}