/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {TablesWidgetOrders} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetOrders'
import EngageWidgetMyProfile from '../../../_metronic/partials/widgets/_new/engage/EngageWidgetMyProfile'
import OverviewMyProfile from '../../modules/apps/myprofile/OverviewMyProfile'
import MyProfileSettings from '../../modules/apps/myprofile/MyProfileSettings'
import BillingMyPorfile from '../../modules/apps/myprofile/BillingMyProfile'
import {axiosClientAuth} from '../../utils'
import OperatorDataWrapper from '../operatorData/OperatorData'
import {UserManagement} from '../userManagement/UserManagement'

export enum MyProfileTabs {
  'overview' = 'overview',
  'settings' = 'settings',
  'billing' = 'billing',
  'accountcontrol' = 'accountcontrol',
  'operatordata' = 'operatordata',
}

const MyProfile: FC = () => {
  const [tab, setTab] = useState(MyProfileTabs.overview)
  const [preferences, setPreferences] = useState<any>()

  let [tabComponent, setTabComponent] = useState(<></>)

  const [data, setData] = useState<any>({})

  useEffect(() => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => setData(res.data?.data))
      .catch((err) => console.log(err))
    axiosClientAuth
      .get('/common/notification-preference')
      .then((resp) => setPreferences(resp.data?.data))
      .catch((err) => {})
  }, [])

  useEffect(() => {
    if (data) {
      switch (tab) {
        case MyProfileTabs.overview:
          setTabComponent(<OverviewMyProfile data={data} setTab={setTab} />)
          break
        case MyProfileTabs.settings:
          setTabComponent(<MyProfileSettings data={data} preferences={preferences} />)
          break
        case MyProfileTabs.accountcontrol:
          setTabComponent(<UserManagement isViewAll={true} />)
          break
        case MyProfileTabs.operatordata:
          setTabComponent(<OperatorDataWrapper />)
          break
      }
    }
  }, [tab, data])

  return (
    <>
      <EngageWidgetMyProfile tab={tab} setTab={setTab} data={data} />
      {tabComponent}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MyProfileWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('myprofile')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({id: 'My Profile'})}</PageTitle>
      <MyProfile />
    </>
  )
}

export {MyProfileWrapper}
