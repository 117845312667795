import {useState} from 'react'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../helpers'

const ModalRouteInfo = ({show, setShow, routeData, name}) => {
  return (
    <Modal
      className='modal-sticky modal-lg modal-sticky-lg modal-sticky-bottom-right'
      show={show}
      animation={true}
      centered={true}
    >
      <div className='modal-header'>
        <h2>Route Info</h2>
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => setShow(false)}
        >
          <KTIcon iconName='cross' className='text-gray fs-1' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
          data-kt-stepper='true'
        >
          <div
            className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'
            id='routebox'
          >
            <div className='stepper-nav ps-lg-10'>
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <span className='menu-icon'>
                      <span className='svg-icon svg-icon-2'>
                        <i className='fa fa-route text-white' />
                      </span>
                    </span>
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Details</h3>
                    <div className='stepper-desc'>{name}</div>
                  </div>
                </div>
                <div className='stepper-line h-40px' />
              </div>
              {routeData?.map((item, index) => (
                <div key={index} className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check' />
                      <span className='stepper-number'>{index + 1}</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>
                        {item?.node_info?.node_name}
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id={`tooltip-manage-ip-${item?.ip}`}>{item?.ip}</Tooltip>
                          }
                        >
                          <i className='fas fa-exclamation-circle ms-2 fs-7' />
                        </OverlayTrigger>
                      </h3>
                      <span className='text-muted'>{item?.node_info?.node_type}</span>
                    </div>
                  </div>
                  {index !== routeData?.length - 1 && <div className='stepper-line h-40px' />}
                </div>
              ))}
            </div>
          </div>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <img
              style={{width: '350px', transform: 'scaleX(-1)'}}
              src='/media/illustrations/sketchy-1/5.png'
              alt=''
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalRouteInfo
