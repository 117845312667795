import {DatePicker} from 'antd'
import {KTIcon} from '../../../_metronic/helpers'
import {useLayout} from '../../../_metronic/layout/core'

export default function ManageTemplateToolbar() {
  const {RangePicker} = DatePicker
  const {
    setUrlMappingDateFilter,
    listOfPeidForManageTemplate,
    manageTemplatePeidFilter,
    setManageTemplatePeidFilter,
  } = useLayout()

  return (
    <div className='d-flex align-items-center'>
      <RangePicker
        className='bg-secondary border-0 text-white'
        style={{height: '34px'}}
        onChange={(value, dateString) => setUrlMappingDateFilter(dateString)}
      />

      <select
        value={manageTemplatePeidFilter}
        className='bg-secondary w-auto ms-3 px-1 border-0'
        style={{height: '40px', borderRadius: '6px'}}
        onChange={(e: any) => setManageTemplatePeidFilter(e.target.value)}
      >
        {['PE ID', ...listOfPeidForManageTemplate].map((item) => (
          <option value={item == 'PE ID' ? '' : item}>{item}</option>
        ))}
      </select>

      {manageTemplatePeidFilter ? (
        <button
          className='btn h-40px btn-danger ms-3 d-flex align-items-center'
          onClick={() => setManageTemplatePeidFilter('')}
        >
          Clear
          <KTIcon iconName='cross' className='text-white fs-1 ms-5' />
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}
