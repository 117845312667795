/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {KTIcon} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  success: number
  failed: number
  total: number
}

const CardsWidgetSMSDetails: FC<Props> = ({className, success, failed, total}) => {
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}

            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{total}</span>

            {/* <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
            </span> */}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>SMS sent</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Success</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{success}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Failed</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{failed}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardsWidgetSMSDetails}
