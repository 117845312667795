import {useEffect, useState} from 'react'
import {CardsWidget171} from '../../../../_metronic/partials/widgets/_new/cards/CardsWidget171'
import {axiosClientAuth} from '../../../utils'

const OverviewTickets = () => {
  const [data, setData] = useState([])
  const [done, setDone] = useState(false)

  useEffect(() => {
    axiosClientAuth
      .get('/support/admin-support-ticket')
      .then((resp) => {
        setData(resp.data?.data)
        setDone(true)
      })
      .catch(() => {
        setDone(true)
      })
  }, [])

  return (
    <div className={`card card-flush g-5 g-xl-10 mb-5 mb-xl-10 bg-primary`}>
      <div className='card-body'>
        <div className='row align-items-center'>
          <div className='col-md-6 col-lg-6 col-xl'>
            {done && (
              <CardsWidget171
                className='h-lg-100 h-100 bg-transparent border-0'
                mLables={{
                  Open: data?.filter((item: any) => item.status == 'open').length || 0,
                  Processing: data?.filter((item: any) => item.status == 'processing').length || 0,
                  Resolved: data?.filter((item: any) => item.status == 'resolved').length || 0,
                }}
                mColors={{Resolved: 'bg-success', Open: 'bg-danger', Processing: 'bg-warning'}}
                heading='Recent Tickets'
                subHeading='Tickets'
              />
            )}
          </div>
          <div className='col-sm-6 d-flex justify-content-center'>
            <img
              src='/media/svg/illustrations/easy/7.svg'
              className='h-200px h-lg-250px my-n6'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewTickets
