/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {KTIcon} from '../../../../helpers'
import {getCSS, getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import ApexCharts from 'apexcharts'

type Props = {
  className: string
  chartSize?: number
  mLables?: Object
  mColors?: Object
  heading?: string
  subHeading?: string
}

const CardsWidget171: FC<Props> = ({
  className,
  chartSize = 210,
  mLables = {},
  mColors = {},
  heading = '',
  subHeading = '',
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, mLables])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height')) + 10

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, chartSize, mLables, mColors)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  let sumOfLabels = 0
  Object.values(mLables).forEach((item) => {
    console.log(item)
    sumOfLabels += parseInt(item)
  })

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}

            <span className='fs-1 fw-bold text-dark me-2 lh-1 ls-n2'>{heading}</span>

            {/* <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
            </span> */}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Total {sumOfLabels} {subHeading} Registred
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center h-175px w-175px pt-2 position-relative me-15 mb-7'>
          <div className='position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center'>
            <span className='fs-2qx fw-bold'>{sumOfLabels}</span>
            <span className='fs-6 fw-semibold text-gray-400'>Total {subHeading}</span>
          </div>
          <div
            ref={chartRef}
            className='mt-2'
            id='kt_card_widget_17_chart'
            style={{height: chartSize + 'px'}}
          />
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid pe-11 mb-5'>
          {Object.keys(mLables).map((key) => (
            <div className='d-flex fw-semibold align-items-center mb-3'>
              <div className={`bullet w-8px h-3px rounded-2 ${mColors[key]} me-3`}></div>
              <div className='text-gray-500 flex-grow-1 me-4'>{key}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{mLables[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function getChartOptions(height: number, width: number, mLables: any, mColors: Object) {
  return {
    series: Object.values(mLables),
    labels: Object.keys(mLables),
    colors: ['#c24848', '#e4f05d', '#48c26a'],
    chart: {
      height,
      width,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height,
            width,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  }
}

export {CardsWidget171}
