/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useLayout} from '../../../layout/core'
import {axiosClientAuth} from '../../../../app/utils'

type Props = {
  name: string
  job: string
  status?: string
  item?: any
  sendMessage?: any
}

const Card1: FC<Props> = ({name, job, status, item, sendMessage}) => {
  const {setCurrentTicket} = useLayout()

  return (
    <div className='card h-100'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='d-flex justify-content-between w-100'>
          <span className='text-gray-600 my-2'>PE ID : {item?.created_by?.pe_id}</span>

          {status == 'open' ? (
            <span className='badge badge-danger my-1'>Open</span>
          ) : status == 'resolved' ? (
            <span className='badge badge-success text-light my-1'>Resolved</span>
          ) : (
            <span className='badge badge-warning my-1'>Processing</span>
          )}
        </div>

        <div className='d-flex min-w-100 mb-5 mt-5'>
          <div
            className={`flex-fill border border-gray-300 border-dashed h-100px bg-light-${
              status == 'open' ? 'danger' : status == 'resolved' ? 'success' : 'warning'
            } text-center rounded py-3 px-4`}
          >
            <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
              {name}
            </a>
            <div className='fw-bold text-gray-400'>{job.substring(0, 110)}</div>
          </div>
        </div>

        <div className='d-flex flex-center gap-5'>
          <button
            className='btn btn-sm btn-light fw-bolder'
            onClick={() => setCurrentTicket(item)}
            id='kt_drawer_ticket_detail_toggle'
          >
            View Details
          </button>

          <button
            className='btn btn-sm btn-light-primary fw-bolder'
            onClick={sendMessage}
            id='kt_drawer_chat_toggle'
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  )
}

export {Card1}
